import React from "react"
import { Link } from "gatsby"

import "./footer.scss"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container_12">
        <div className="wrapper">
          <div className="grid_12">
            <ul className="footer-menu hide-ip">
              <li>
                <Link
                  to="/"
                  className="navItem"
                  activeClassName=" active-nav-item"
                >
                  Start
                </Link>
              </li>
              <li>
                <Link
                  to="/opony-tychy"
                  className="navItem"
                  activeClassName=" active-nav-item"
                  partiallyActive={true}
                >
                  Opony
                </Link>
              </li>
              <li>
                <Link
                  to="/galeria"
                  className="navItem"
                  activeClassName=" active-nav-item"
                  partiallyActive={true}
                >
                  Galeria
                </Link>
              </li>
              <li>
                <Link
                  to="/kalkulator-opon"
                  className="navItem"
                  activeClassName=" active-nav-item"
                  partiallyActive={true}
                >
                  Kalkulator
                </Link>
              </li>
              <li>
                <Link
                  to="/oferta"
                  className="navItem"
                  activeClassName=" active-nav-item"
                  partiallyActive={true}
                >
                  Oferta
                </Link>
              </li>
              <li>
                <Link
                  to="/polecamy"
                  className="navItem"
                  activeClassName=" active-nav-item"
                  partiallyActive={true}
                >
                  Polecamy
                </Link>
              </li>
              <li className="last-item">
                <Link
                  to="/kontakt"
                  className="navItem"
                  activeClassName=" active-nav-item"
                  partiallyActive={true}
                >
                  Kontakt
                </Link>
              </li>
            </ul>
            <div className="footer-text">
              <span>KostaAuto&nbsp;&nbsp;&copy; 2024</span>
              <Link to="/polityka-cookies">Polityka cookies</Link>
            </div>
            <div className="footer-link"></div>

            <br />
            <br />

            <span className="company_info">
              KostaAuto - Opony, Wulkanizacja, Wymiana opon,
            </span>
            <br />
            <span className="company_info">
              ul. Myśliwska 140, Tychy 43-100
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
